import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import { Container } from 'components/Container'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { Title, TitleH1 } from 'components/TitleComponent'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { SliderComponent } from 'components/SliderComponent'
import { indent } from 'styles/indent'
import FullScreen from 'assets/icons/fullScreen.svg'
import { ModalCarousel } from 'components/ModalCarousel'
import { ProjectData } from 'layout/Project'
import { sendEvent } from 'tracking'
import { headerBg } from 'styles/headerBg'
import { sendForm } from 'components/form/api'
import { Button } from 'components/Button'
import Slider from 'react-slick'
import { LeftSidebar, RightSidebar } from 'components/SideBar'

const ExampleOfProjectWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${backgroundColors.contact};
    position: relative;
    border-bottom: 1px solid ${colors.dark};
    ${mobileAfterBorder}
    :before {
        ${headerBg}
    }
`

const SubTitle = styled.h3`
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${colors.dark};
    margin-bottom: 32px;
    @media (min-width: ${displayWidth.tablet}) {
        text-align: left;
    }
`
const TitleStyledH1 = styled(TitleH1)`
    margin-top: 8px;
    @media (min-width: ${displayWidth.tablet}) {
        margin-top: 32px;
        margin-left: 0;
        margin-right: 0;
    }
`
const TitleStyled = styled(Title)`
    @media (min-width: ${displayWidth.tablet}) {
        margin-left: 0;
        margin-right: 0;
    }
`
const HeroColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${indent.heroColumnTablet} 24px;
    border-bottom: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
        border-right: 1px solid ${colors.dark};
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 0 ${indent.heroColumnDesktop} 24px;
    }
`

const ImgStyled = styled(Img)<{ fluid: FluidObject }>`
    width: 100%;
    height: 100%;
    max-height: 600px;

    img {
        max-height: 600px;
        object-fit: contain;
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.white};
    position: relative;
    overflow-x: hidden;
`

const FullScreenButton = styled(FullScreen)`
    fill: ${colors.white};
    stroke: ${colors.dark};
    stroke-width: 1px;
    position: absolute;
    top: 10px;
    right: 26px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 3;
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
    @media (min-width: ${displayWidth.desktop}) {
        right: 10px;
    }
`

const ButtonStyled = styled(Button)`
    a {
        text-decoration: none;
        color: #fff;
        font-size: 18px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        margin: 30px 0;
    }
`

export const ExampleOfProjectArch = ({
    withH2Header = false,
}: {
    withH2Header?: boolean
}) => {
    const { i18n, t } = useTranslation()
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [currentSlideS, setCurrentSlideS] = useState(0)
    const sliderRefD = useRef<Slider | null>(null)
    const sliderD = sliderRefD.current
    const data = useStaticQuery(graphql`
        query {
            desktop: allFile(
                filter: { relativeDirectory: { eq: "projectExampleArch" } }
                sort: { fields: absolutePath }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(
                                srcSetBreakpoints: [800, 1600]
                                quality: 100
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                            parent {
                                ... on File {
                                    name
                                }
                            }
                        }
                    }
                }
            }
            allExamplesOfProjectsYaml {
                edges {
                    node {
                        architectTitle
                        architectDescription
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
            allExampleArchImagesDataYaml {
                edges {
                    node {
                        items {
                            title
                            alt
                        }
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)
    const examplesOfProjectsYaml = getDataByLanguage(
        data.allExamplesOfProjectsYaml,
        i18n.language
    )

    const imagesData = getDataByLanguage(
        data.allExampleArchImagesDataYaml,
        i18n.language
    )

    const { architectTitle, architectDescription } = examplesOfProjectsYaml
    const sliderSettings = {
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    dots: true,
                },
            },
        ],
    }

    return (
        <ExampleOfProjectWrapper>
            <LeftSidebar background={backgroundColors.contact} />
            <Container columns={'1fr'} tabletColumns={'1fr 2fr'}>
                <HeroColumn>
                    {withH2Header ? (
                        <TitleStyled>{architectTitle}</TitleStyled>
                    ) : (
                        <TitleStyledH1>{architectTitle}</TitleStyledH1>
                    )}
                    <SubTitle> {architectDescription}</SubTitle>
                    <ButtonStyled
                        onClick={() => {
                            sendForm(`Скачали проект Architect`, {})
                            sendEvent('Click', {
                                eventCategory: 'DownloadExempleArchitect',
                                placement: 'ExampleOfProjectArchitect',
                            })
                        }}
                        id="DownloadExampleArchitect"
                    >
                        <a
                            href={'https://clearline.com.ua/architecture.pdf'}
                            download
                        >
                            {t('downloadProject')}
                        </a>
                    </ButtonStyled>
                </HeroColumn>
                <Wrapper>
                    <FullScreenButton
                        onClick={() => {
                            sendEvent('FullScreen', {
                                eventCategory: 'SliderExample',
                                placement: 'ExampleOfProject',
                            })
                            setModalIsOpen(true)
                        }}
                    />
                    <SliderComponent
                        {...sliderSettings}
                        forwardRef={sliderRefD}
                        afterChange={(current: number) => {
                            setCurrentSlideS(current)
                            sendEvent('ShowSlide', {
                                eventCategory: 'SliderExample',
                                currentSlide: `${current}`,
                                placement: 'ExampleOfProject',
                            })
                        }}
                    >
                        {data.desktop.edges.map(
                            (
                                item: {
                                    node: {
                                        childImageSharp: {
                                            fluid: FluidObject
                                            parent: { name: string }
                                        }
                                    }
                                },
                                index: number
                            ) => {
                                return (
                                    <ImgStyled
                                        key={index}
                                        fluid={item.node.childImageSharp.fluid}
                                        imgStyle={{
                                            objectFit: 'containe',
                                        }}
                                        alt={imagesData.items[index]?.alt}
                                        title={imagesData.items[index]?.title}
                                    />
                                )
                            }
                        )}
                    </SliderComponent>
                </Wrapper>
            </Container>
            <RightSidebar />
            <ModalCarousel
                data={data.desktop.edges.map(
                    ({ node }: { node: ProjectData }) => node
                )}
                setCurrentSlideS={setCurrentSlideS}
                isModalOpen={isModalOpen}
                closeHandler={() => {
                    setModalIsOpen(false)
                    sliderD !== null && sliderD.slickGoTo(currentSlideS)
                }}
                initialSlideIndex={currentSlideS}
            />
        </ExampleOfProjectWrapper>
    )
}
